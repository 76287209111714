import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer:false,
    currentIndex: 0,
    categorias: [],
    restaurante:null,
    platos:{},
    plato:{},
    pedido:[],
    title:"Bienvenidos",
    user:null,
    extraUserData:null,
    dialog:{
      title: "",
      text: "",
      show:false
    }
  },
  getters: {
  },
  mutations: {
    setDrawer(state,newState){
      state.drawer = newState
    },
    drawerToggle(state) {
      state.drawer = !state.drawer
    },
    setCategorias(state,categorias) {
      state.categorias = categorias
    },
    setRestaurante(state,restaurante) {
      state.restaurante = restaurante
      window.localStorage.setItem('restaurante',JSON.stringify(restaurante))
    },
    setRestaurante2(state,restaurante) {
      state.restaurante = restaurante
    },    
    setPlato(state,plato){
      state.plato = plato
    },
    setTitle(state,newTitle){
      state.title = newTitle
    },
    setUser(state,user) {
      state.user = user
    },
    setExtraUserData(state,extraUserData) {
      state.extraUserData = extraUserData
    },
    showDialog(state,[title,text]) {
      state.dialog.title = title
      state.dialog.text = text
      state.dialog.show = true
    },
    hideDialog(state) {
      state.dialog.show = false
    },
    setCurrentIndex(state, index){
      state.currentIndex = index
    },
    setPlatos(state, platos){
      state.platos = platos
    },
    anotar(state,plato) {
      if (!state.pedido.find((it)=>it.nombre==plato.nombre))
        state.pedido.push(plato)
    },
    desanotar(state,index) {
      state.pedido.splice(index,1)
    }
  },
  actions: {
    loadUsername({commit}){
      this.$firebase.auth().onAuthStateChanged(user=>{
        if(user){
          commit('setUser', user.uid)
        }
      })
    }
  },
  modules: {
  }
})
