<template>
  <v-app>
    <nav>
      <Header />
    </nav>
    <router-view />
    <v-dialog
      v-model="$store.state.dialog.show"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $store.state.dialog.title }}
        </v-card-title>

        <v-card-text>
          {{ $store.state.dialog.text }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="$store.commit('hideDialog')"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>

</template>

<script lang="js">
import login from "./utils/login"
import Header from './components/Header.vue';
import axios from "axios";
const auth = { headers: { 'Authorization': "Basic " + btoa("admin:1234") } }
function setQueryStringDefaults(){
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let id 
    if( urlParams.has('t') ){
        id = urlParams.get('t');
        return id
    }

    if( urlParams.has('id') ){
        id = urlParams.get('id');
        return id
    }
    return false
}
export default {
  components: {
    Header,
  },
  name: 'App',
  data: () => ({
    //
  }),
  mounted() {
    /***
     * Ejeplo de como guardar datos en el navegador local para que queden cuando la app se cierra y se abre 
     * se usa localStorage  es clave valor: los valores objetos deben ser convertidos mediante JSON.parse y JSON.stringify
     * let objeto = window.localStorage.getItem('clave')
     * window.localStorage.setItem('clave','valor')
     * window.localStorage.removeItem('clave')
     */
    this.loadPreviousUser()
    let idq = setQueryStringDefaults()
    if (idq) {
      this.getRestaurante(idq)
    } else {
      this.$goHome(this)
    }
  },
  methods: {
    loadPreviousUser() {
      let user = window.localStorage.getItem('user');
      if (user) {
        this.$store.commit("setUser",JSON.parse(user))
      }
      let resto = window.localStorage.getItem('restaurante');
      if (resto) {
        this.$store.commit("setRestaurante2",JSON.parse(resto))
      }
    },
    getRestaurante(tokenOrID) {
      axios.get(process.env.VUE_APP_API_URL+'t/'+tokenOrID,auth)
      .then((res)=>{
        this.$store.commit('setRestaurante',res.data);
        login.asociarRestaurante(this)
        this.$router.push("/categorias")
      }).catch((err)=>{
        console.error( err)
        this.$goHome(this)
      })
    },    
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

a.gflag {
    vertical-align: middle;
    font-size: 24px;
    padding: 1px 0;
    background-repeat: no-repeat;
    background-image: url(//gtranslate.net/flags/24.png);
}

a.gflag img {
    border: 0;
}

a.gflag:hover {
    background-image: url(//gtranslate.net/flags/24a.png);
}

#goog-gt-tt {
    display: none !important;
}

.goog-te-banner-frame {
    display: none !important;
}

.goog-te-menu-value:hover {
    text-decoration: none !important;
}

body {
    top: 0 !important;
}

#google_translate_element2 {
    display: none !important;
}

.vg-flag {
  width: 50px;
  vertical-align: middle;
  margin-right: 5px;
}
.vg-body {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;  
}
.vg-text {
  color: black;
}

</style>
