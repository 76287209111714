import { initializeApp} from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { getFirestore, collection, getDocs, setDoc, doc } from 'firebase/firestore/lite';
import { getAuth, sendEmailVerification, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup,GoogleAuthProvider,sendPasswordResetEmail,TwitterAuthProvider , signOut, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import User from '@/model/User';
const authback = { headers: { 'Authorization': "Basic " + btoa("admin:1234") } }

 const firebaseConfig = {
    apiKey: "AIzaSyC_5WnSLUKtlXAamSWg6sV2T_3v0zpVhtc",
    authDomain: "mejor-salimos.firebaseapp.com",
    databaseURL: "https://mejor-salimos.firebaseio.com",
    projectId: "mejor-salimos",
    storageBucket: "mejor-salimos.appspot.com",
    messagingSenderId: "704705896145",
    appId: "1:704705896145:web:4bfaefc7f40e0585f46ff0"    
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
import axios from "axios"

console.log("Cargó Firebase")

export default {
  saveUserFS(user) {
    const docRef = doc(db, "users", user.uid );
    setDoc(docRef, user).then((response) => {
      console.log(response)
    }).catch((err)=>{
      console.log(err)
    })
  },
  saveUserLocalStorage(user) {
    window.localStorage.setItem("user", JSON.stringify(user))
  },
  asociarRestaurante(that){
    let userId = that.$store.state.user?.uid
    let restId = that.$store.state.restaurante?.restaurant?.id
    if (userId && restId) {
      axios.post(process.env.VUE_APP_API_URL+"portfolio-cartas/add/"+userId+"/"+restId,null,authback).then((res) => {
        console.log(res)
      }).catch((err) => {
        console.log(err)}
      )
    }
  },
  loginUserPassword(that, email, password) {
    signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        console.log(res);
        const usuario = {
          email: res.user.email,
          uid: res.user.uid,
        };
        that.$store.commit("setUser", usuario);
        this.asociarRestaurante(that)
        that.$goHome(that);
        // Guardar usuario  
        this.saveUserLocalStorage(usuario)
        this.saveUserFS(JSON.parse(JSON.stringify(res.user)))         
      })
      .catch((error) => {
        if (error.code == "auth/wrong-password") {
          that.$store.commit("showDialog", [
            "Error de Usuario",
            "e-mail o contraseña equivocados",
          ]);
        } else if (error.code == "auth/invalid-email") {
          that.$store.commit("showDialog", [
            "Error de Usuario",
            "Usuario debe tener un e-mail válido",
          ]);
        }
      });
  },
  presentarRecaptcha() {
    return new RecaptchaVerifier('recaptcha-container', {}, auth);
  },
  loginPhone(phoneNumber, appVerifier) {
    return signInWithPhoneNumber(auth, phoneNumber, appVerifier)
  },
  registerUser(email, password) {
    return createUserWithEmailAndPassword(auth, email, password)
  },  
  sendVerificationMail() {
    return sendEmailVerification(auth.currentUser)
  },  
  loginConGoogle(that) {
    const provider = new GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/user.phonenumbers.read");
    provider.addScope("https://www.googleapis.com/auth/user.addresses.read");
    provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
    provider.addScope("https://www.googleapis.com/auth/userinfo.email");
    provider.addScope("https://www.googleapis.com/auth/user.phonenumbers.read");    
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        console.log(token);
        console.log(result);
        let usuario = User.crearUser(result.user?.providerData[0]?.email,result.user.uid,result._tokenResponse.fullName,result.user.photoURL,result.providerId)
        that.$store.commit("setUser", usuario);
        this.asociarRestaurante(that)
        console.log(usuario)
        that.$goHome(that);
        // Guardar usuario  
        this.saveUserLocalStorage(usuario)        
        this.saveUserFS(JSON.parse(JSON.stringify(result.user))) 
       })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        //const email = error.customData.email;
        // The AuthCredential type that was used.
        //const credential = GoogleAuthProvider.credentialFromError(error);
        that.$store.commit("showDialog", [errorCode, errorMessage]);
      });
  },
  loginConTwitter(that) {
    const provider = new TwitterAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/user.phonenumbers.read");
    provider.addScope("https://www.googleapis.com/auth/user.addresses.read");
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = TwitterAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const secret = credential.secret;
        console.log(token);
        console.log(secret);
        let usuario = User.crearUser(result.user.email,result.user.uid,result._tokenResponse.fullName,result.user.photoURL)
        that.$store.commit("setUser", usuario);
        this.asociarRestaurante(that)
        that.$goHome(that);
        // Guardar usuario  
        this.saveUserLocalStorage(usuario)       
        this.saveUserFS(JSON.parse(JSON.stringify(result.user)))         
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        that.$store.commit("showDialog", [errorCode, errorMessage]);
      });
  },
/*  loginConFacebook(that) {
    const provider = new FacebookAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/user.phonenumbers.read");
    provider.addScope("https://www.googleapis.com/auth/user.addresses.read");
    signInWithPopup(auth, provider)
      .then((result) => {

        that.$store.commit("setUser", User.crearUser(result.user.email,result.user.uid));
        this.asociarRestaurante(that)
        that.$router.push("/");
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        console.log(token)
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        that.$store.commit("showDialog", [errorCode, errorMessage]);
      });
  },*/
  registrarUsuarioPorTelefono(that,result) {
      let usuario = new User(result.user.email, result.user.uid,result.user.phoneNumber)
      that.$store.commit("setUser", usuario);
      this.asociarRestaurante(that)
      that.$goHome(that);
      // Guardar usuario  
      this.saveUserLocalStorage(usuario)
      this.saveUserFS(JSON.parse(JSON.stringify(result.user)))       
  },
  async traerDatosFirestore() {
    const usersCol = collection(db, "users");
    const userSnapshot = await getDocs(usersCol);
    const userList = userSnapshot.docs.map((doc) => doc.data());
    return userList;
  },
  logout(that) {
    signOut(auth)
      .then(() => {
        that.$store.commit("setUser", null);
        window.localStorage.removeItem("user");
        that.$goHome(that);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  resetPassword(email){
    return sendPasswordResetEmail(auth,email)
  },
};