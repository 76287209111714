import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import './registerServiceWorker'
import VGoogleTranslate from "v-google-translate";
import routerMixin from './mixins/routing';
Vue.mixin(routerMixin);
Vue.use(VGoogleTranslate)

Vue.config.productionTip = false
Vue.use(VueTelInput);
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
