<template lang="html">
  <section>
    <NavBar />
    <div  class="template">
    <h1 class="img">Lector Menu Qr</h1>
    <v-img contain src="@/assets/img/qr-landing.png" alt="qr" :style="{ maxHeight: '50vh'}"/>
    <vue-qr-reader class="img" draw-on-found="false" vide-width="640" vide-height="1000" @code-scanned="codeArrived"></vue-qr-reader>
    <div class="my-2 img">
            <v-btn color="primary" rounded large @click="goHome()">
              CANCELAR
            </v-btn>
          </div>
  </div>
  </section>
 
</template>

<script>
  import NavBar from './NavBar.vue'
  import VueQrReader from 'vue-qr-reader/dist/lib/vue-qr-reader.umd.js';
  export default {
    mounted() {
      this.$store.commit('setTitle',"Leer Código QR")
    },
      components: {
          VueQrReader,NavBar
      },
      methods: {
          goHome() {
            this.$goHome(this)
          },
          codeArrived (code) {
            //https://mejorsalimos.com/app/?t=3cd07af2=aaaa=aaa
            //https://mejorsalimos.com/app/?t
            //3cd07af2
            if (!code.includes("mejorsalimos.com")){
              alert("QR No Válido")
              this.$router.go()
              return
            }
            let arraySeparadoPorSeparador = new String(code).split('=')
            let token = arraySeparadoPorSeparador[1]
            console.log(code)
            this.$router.push('/resto/'+token)
          }
      }
  }
</script>
<style scoped lang="css">
  .img {
    display: flex;
    justify-content: center;
    margin-top: 6px;
  }
</style>