<template lang="pug">
section.src-components-header
  div
    v-navigation-drawer.white.accent-4(v-model='$store.state.drawer' absolute='' temporary='' ligth='')
      div(style="background-color:#b0bec5")
        p Mejor Salimos {{ version }}
        v-avatar.mb-5(size='5rem')
          v-img(src="@/assets/icon.png")
        br
      v-list(nav='' dense='')
        v-list-item-group(v-model='group' active-class='black--text text--accent-4')
          v-list-item(to='/mi-perfil' v-if='$store.state.user')
            v-icon(light='')
              | mdi-account-circle
            v-list-item-title
              h3 Mi Perfil
          v-list-item(to='/lectorqr')
            v-icon(light='')
              | mdi-qrcode-scan
            v-list-item-title
              h3 Leer C&oacute;digo QR
          v-list-item(to='/portfolio-cartas' v-if='$store.state.user')
            v-icon(light='')
              | mdi-silverware-variant
            v-list-item-title
              h3 Porfolio de Cartas
          v-list-item(to='/login' v-else='')
            v-icon(light='')
              | mdi-silverware-variant
            v-list-item-title
              h3 Porfolio de Cartas
          v-list-item(@click='logout()' v-if='$store.state.user')
            v-icon(light='')
              | mdi-logout-variant
            v-list-item-title
              h3 Cerrar Sesi&oacute;n
          v-list-item(to='/login' v-else='')
            v-icon(light='')
              | mdi-login-variant
            v-list-item-title
              h3 Iniciar Sesi&oacute;n
          v-list-item(to='/misanotados' v-if='$store.state.restaurante?.restaurant?.usaMisAnotados')
            v-icon(light='')
              | mdi-pencil-outline
            v-list-item-title
              h3 Mis anotados
</template>
<script lang="js">
import login from "../utils/login"
import { VERSION } from '../version.js'
export default {
  name: 'src-components-header',
  props: [],

  mounted() {
  },
  data: () => ({
    group: null,
    dialog: false,
    version: VERSION,
  }),

  watch: {
    group() {
      this.$store.commit("setDrawer",false)
    },
  },
  methods: {
    logout() {
      login.logout(this)
    },
    anotar() {
      this.snackbar=false
      this.$store.commit("anotar",this.$store.state.plato)
      this.snackbarText=this.$store.state.plato.nombre +" anotado en mis anotados"
      this.snackbar=true
    }
  },
  computed: {

  }
}


</script>

<style scoped lang="css">
.src-components-header {
  background-color: primary;
}

.menu {
  display: flex;
  background-color: primary;
}
</style>
