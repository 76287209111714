<template lang="pug">

section.src-components-nav-bar-vue
  .ma-12
  v-app-bar(color='primary' dense dark fixed)
    v-app-bar-nav-icon(@click.stop='$store.commit("drawerToggle")')
    v-btn(icon='' @click="homeOrscan")
      v-icon mdi-home
    v-btn(icon='' @click='$router.go(-1)')
      v-icon mdi-arrow-left
    v-toolbar-title {{ title || $store.state.title }}
    v-spacer
    slot
    v-dialog(v-model='dialog' fullscreen)
      template(v-slot:activator='{ on, attrs }')
        v-btn(icon='' v-bind='attrs' v-on='on')
          v-icon mdi-translate
      v-card
        v-card-title.text-h5.grey.lighten-2
          | Seleccione Idioma
        v-card-text
        v-google-translate(
            :defaultLanguageCode="defaultLanguageCode"
            :defaultPageLanguageCode="defaultPageLanguageCode"
            :fetchBrowserLanguage="false"
            @select="languageSelectedHandler")
        v-card-actions
          v-spacer
          v-btn(color='primary' text='' @click='dialog = false')
            | cerrar

</template>

<script lang="js">

  export default  {
    name: 'src-components-nav-bar-vue',
    props: ['title'],
    components: {

    },
    mounted () {
      setTimeout(() => {
        const testSourceLanguageEl = this.$refs.testSourceLanguage;
        console.log(testSourceLanguageEl);
        /*if (testSourceLanguageEl.classList.contains("notranslate")) {
          testSourceLanguageEl.classList.remove("notranslate");
        }*/
      }, 2000);
    },
    data () {
      return {
        dialog:false,
        defaultLanguageCode: "es",
        defaultPageLanguageCode: "es-AR",        
      }
    },
    methods: {
      languageSelectedHandler(info) {
        console.log(info)
        
      },      
      homeOrscan() {
        this.$goHome(this)
      },
    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
  .src-components-nav-bar-vue {

  }
</style>
