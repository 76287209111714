import Vue from 'vue'
import VueRouter from 'vue-router'
import LectorQr from '../components/LectorQr.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/lectorqr",
    name: "lectorqr",
    component: LectorQr,
  },
  {
    path: "/categorias",
    name: "categorias",
    component: () => import("../components/Categorias.vue"),
  },
  {
    path: "/verify-email",
    name: "verifyEmail",
    component: () => import("../components/verifyEmail.vue"),
  },
  {
    path: "/",
    name: "home",
    component: () => import("../components/wait.vue"),
  },
  {
    path: "/categorias/:idcategoria",
    name: "categoria",
    component: () => import("../components/Categoria.vue"),
  },
  {
    path: "/resto/:token",
    name: "token_restaurant",
    component: () => import("../components/Categorias.vue"),
  },
  {
    path: "/plato/:id",
    name: "plato",
    component: () => import("../components/Plato.vue"),
  },
  {
    path:'/login',
    name:'login',
    component: ()=> import ('../components/LoginUser.vue'),
  },  
  {
    path:'/login-forget',
    name:'olvidaste',
    component: ()=> import ('../components/olvidasteContrasena.vue'),
  }, 
  { 
    path:'/registration',
    name:'registro',
    component: ()=> import ('../components/registro.vue')
  },
  { 
    path:'/login-landing',
    name:'cartaDePlatos',
    component: ()=> import ('../components/CartaDePlatos.vue')
  },
  { 
    path:'/login-phone',
    name:'login-phone',
    component: ()=> import ('../components/LoginPhone.vue')
  },
  { 
    path:'/portfolio-cartas',
    name:'portfolio-cartas',
    component: ()=> import ('../components/PortfolioCartas.vue')
  },
  { 
    path:'/mi-perfil',
    name:'perfil',
    component: ()=> import ('../components/MiPerfil.vue')
  },
  { 
    path:'/misanotados',
    name:'misanotados',
    component: ()=> import ('../components/MisAnotados.vue')
  },

]
const router = new VueRouter({
  routes
})

export default router
