export default {
    methods: {
        $goHome(that) {
            if (that.$store.state.restaurante) {
                that.$router.push("/categorias")
                console.log("categorias")
                return
            }
            if(that.$store.state.user) {
                that.$router.push("/portfolio-cartas")
                console.log("portfolio-cartas")
            } else {
                that.$router.push("/lectorqr")
                console.log("/lectorqr")
            }
        }
    }
}