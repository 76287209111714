module.exports = 
    class User {
        email
        uid
        fullName
        photoURL
        providerId
        phoneNumber
        static crearUser(email,uid,fullName,photoURL,providerId) {
            return new User(email,uid,fullName,photoURL,providerId,null)
        }
        constructor(email,uid,fullName,photoURL,providerId,phoneNumber) {
            this.email = email;
            this.uid = uid;
            this.fullName=fullName;
            this.photoURL=photoURL
            this.providerId=providerId
            this.phoneNumber=phoneNumber;
        }        
    }
